<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
        <!---->
      </div>

      <div class="player listmain type-ssc xjsssc game-xjsssc game-ssc-qt">
        <!-- 前三  begin-->
        <div code="Z1-Z2-Z3" class="data cZ1-Z2-Z3">
          <h3>前三</h3>
          <ul>
            <li balls="b" itid="32851" code="cz|Z1-Z2-Z3" id="cz|Z1-Z2-Z3_32851">
              <ol class="td_name">
                豹子
              </ol>
              <ol id="rate_32851" class="td_rate">
                63.18
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32851" id="cash_32851" type="text" />
              </ol>
            </li>
            <li balls="d" itid="32852" code="cz|Z1-Z2-Z3" id="cz|Z1-Z2-Z3_32852">
              <ol class="td_name">
                对子
              </ol>
              <ol id="rate_32852" class="td_rate">
                2.79
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32852" id="cash_32852" type="text" />
              </ol>
            </li>
            <li balls="s3" itid="32853" code="cz|Z1-Z2-Z3" id="cz|Z1-Z2-Z3_32853">
              <ol class="td_name">
                顺子
              </ol>
              <ol id="rate_32853" class="td_rate">
                12.5
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32853" id="cash_32853" type="text" />
              </ol>
            </li>
            <li balls="s2" itid="32854" code="cz|Z1-Z2-Z3" id="cz|Z1-Z2-Z3_32854">
              <ol class="td_name">
                半顺
              </ol>
              <ol id="rate_32854" class="td_rate">
                1.99
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32854" id="cash_32854" type="text" />
              </ol>
            </li>
            <li balls="z" itid="32855" code="cz|Z1-Z2-Z3" id="cz|Z1-Z2-Z3_32855">
              <ol class="td_name">
                杂六
              </ol>
              <ol id="rate_32855" class="td_rate">
                2.18
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32855" id="cash_32855" type="text" />
              </ol>
            </li>
          </ul>
        </div>
        <!-- 前三 end -->
        <!-- 中三 begin -->
        <div code="Z2-Z3-Z4" class="data cZ2-Z3-Z4">
          <h3>中三</h3>
          <ul>
            <li balls="b" itid="32856" code="cz|Z2-Z3-Z4" id="cz|Z2-Z3-Z4_32856">
              <ol class="td_name">
                豹子
              </ol>
              <ol id="rate_32856" class="td_rate">
                63.18
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32856" id="cash_32856" type="text" />
              </ol>
            </li>
            <li balls="d" itid="32857" code="cz|Z2-Z3-Z4" id="cz|Z2-Z3-Z4_32857">
              <ol class="td_name">
                对子
              </ol>
              <ol id="rate_32857" class="td_rate">
                2.79
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32857" id="cash_32857" type="text" />
              </ol>
            </li>
            <li balls="s3" itid="32858" code="cz|Z2-Z3-Z4" id="cz|Z2-Z3-Z4_32858">
              <ol class="td_name">
                顺子
              </ol>
              <ol id="rate_32858" class="td_rate">
                12.5
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32858" id="cash_32858" type="text" />
              </ol>
            </li>
            <li balls="s2" itid="32859" code="cz|Z2-Z3-Z4" id="cz|Z2-Z3-Z4_32859">
              <ol class="td_name">
                半顺
              </ol>
              <ol id="rate_32859" class="td_rate">
                1.99
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32859" id="cash_32859" type="text" />
              </ol>
            </li>
            <li balls="z" itid="32860" code="cz|Z2-Z3-Z4" id="cz|Z2-Z3-Z4_32860">
              <ol class="td_name">
                杂六
              </ol>
              <ol id="rate_32860" class="td_rate">
                2.18
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32860" id="cash_32860" type="text" />
              </ol>
            </li>
          </ul>
        </div>
        <!-- 中三 end -->
        <!-- 后三 begin -->
        <div code="Z3-Z4-Z5" class="data cZ3-Z4-Z5">
          <h3>后三</h3>
          <ul>
            <li balls="b" itid="32861" code="cz|Z3-Z4-Z5" id="cz|Z3-Z4-Z5_32861">
              <ol class="td_name">
                豹子
              </ol>
              <ol id="rate_32861" class="td_rate">
                63.18
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32861" id="cash_32861" type="text" />
              </ol>
            </li>
            <li balls="d" itid="32862" code="cz|Z3-Z4-Z5" id="cz|Z3-Z4-Z5_32862">
              <ol class="td_name">
                对子
              </ol>
              <ol id="rate_32862" class="td_rate">
                2.79
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32862" id="cash_32862" type="text" />
              </ol>
            </li>
            <li balls="s3" itid="32863" code="cz|Z3-Z4-Z5" id="cz|Z3-Z4-Z5_32863">
              <ol class="td_name">
                顺子
              </ol>
              <ol id="rate_32863" class="td_rate">
                12.5
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32863" id="cash_32863" type="text" />
              </ol>
            </li>
            <li balls="s2" itid="32864" code="cz|Z3-Z4-Z5" id="cz|Z3-Z4-Z5_32864">
              <ol class="td_name">
                半顺
              </ol>
              <ol id="rate_32864" class="td_rate">
                1.99
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32864" id="cash_32864" type="text" />
              </ol>
            </li>
            <li balls="z" itid="32865" code="cz|Z3-Z4-Z5" id="cz|Z3-Z4-Z5_32865">
              <ol class="td_name">
                杂六
              </ol>
              <ol id="rate_32865" class="td_rate">
                2.18
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_32865" id="cash_32865" type="text" />
              </ol>
            </li>
          </ul>
        </div>
        <!-- 后三 end -->
      </div>

      <yushe :disabled="isDisabled" />
    </div>
    <div class="other"></div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
    };
  },
  watch: {
    amount(val) {
      this.rowsData.forEach((item) => {
        if (item.selected) {
          item.cash = val;
        }
      });
      this.$forceUpdate();
    },
  },
  components: {
    yushe,
    topTimer,
  },
  created() {},
  computed: {
    //  预设按钮是否能点击 筛选数据传到下注弹框
    isDisabled() {
      let b = true;
      this.xiazhuList = [];
      this.rowsData.forEach((item) => {
        if (item.cash) {
          this.xiazhuList.push(item);
          b = false;
        }
      });
      if (!b) {
        this.$store.commit("setXZList", this.xiazhuList);
      } else {
        this.$store.commit("setXZList", []);
      }
      return b;
    },
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    // input聚焦 判断显示快速下注
    showOrHideCheck(e, obj) {
      console.log(123, this.quick_b && this.quick_c);
      if (this.quick_b && this.quick_c) {
        this.rowsData.forEach((item) => {
          item.forEach((ele) => {
            if (ele.index == obj.index) {
              ele.b = true;
            } else {
              ele.b = false;
            }
          });
        });
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style scoped>
.listmain .data ul li {
  width: 100%;
}
.listmain > .data > ul:last-child {
  border-bottom: none;
}
.listmain > .data > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.listmain .data h3 {
  border: solid 1px #b9c2cb;
  border-bottom: none;
  border-right: none;
}
.listmain > .data > h3 {
  height: 24px;
  line-height: 24px;
  text-align: center;
  /* background: #e0e0e0; */
  font-weight: 700;
  font-size: 13px;
}
.listmain.game-ssc-kd .data,
.listmain.game-ssc-lh .data,
.listmain.game-ssc-qt .data {
  margin-right: 2px;
  width: calc((100% - 6px) / 3);
}
.listmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -ms-flex-flow: row wrap; */
  /* flex-flow: row wrap; */
  width: 100%;
  clear: both;
}
.listmain > .data {
  margin-top: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.listmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -ms-flex-flow: row wrap;
  flex-flow: row wrap; */
  width: 100%;
  clear: both;
}
/* 头部 dohand 的样式 */
.container .main .dohand .btn2 {
  position: absolute;
  top: 10px;
  width: 62px;
  height: 18px;
  line-height: 18px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ff925f),
    to(#ff7340)
  );
  background: linear-gradient(180deg, #ff925f, #ff7340);
  color: #fff;
  border: 1px solid #ff6835;
  cursor: pointer;
  text-align: center;
}
.container .main .dohand .btn2.a {
  right: 0;
}
.skin-brown .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #b99770),
    to(#dfb55e)
  );
  background: linear-gradient(180deg, #b99770, #dfb55e);
  border: 1px solid #dfb55e;
}
.skin-red .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #d87c86),
    to(#6a1f2d)
  );
  background: linear-gradient(180deg, #d87c86, #6a1f2d);
  border: 1px solid #ab374a;
}
.skin-blue .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #5b8ac7),
    to(#2765b5)
  );
  background: linear-gradient(180deg, #5b8ac7, #2765b5);
  border: 1px solid #1e57a0;
}

.container .main .dohand .btn.none {
  background: #999;
  border: 1px solid #999;
}

.container .main .dohand .btn {
  margin-left: 15px;
  width: 58px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.container .main .dohand .amount input {
  margin-left: 5px;
  border: 1px solid #b0b0b0;
  height: 18px;
  padding: 0 2px;
  width: 65px;
  background: url("../../assets/input.gif") no-repeat 0 0;
  color: #333;
  font-weight: 400;
}
.container .main .dohand .amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  font-weight: 700;
}
.container .main .dohand .preset.selected:after {
  position: absolute;
  content: "";
  left: 3px;
  top: 4px;
  width: 10px;
  height: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 2px solid #666;
  border-bottom: 2px solid #666;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.container .main .dohand .preset:before {
  position: absolute;
  content: "";
  left: 0;
  top: 1px;
  border: 1px solid #bbb;
  height: 14px;
  width: 14px;
}
.container .main .dohand .preset {
  position: relative;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  padding-left: 20px;
  cursor: pointer;
}
.skin-brown .bodyer .container .main .dohand {
  color: #dfb55e;
}
.skin-red .bodyer .container .main .dohand {
  color: #6a1f2d;
}
.skin-blue .bodyer .container .main .dohand {
  color: #38539a;
}
.container .main .dohand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  padding: 10px 0;
  background: #fff;
  line-height: 20px;
}
</style>
